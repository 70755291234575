<template>
  <div class="wrapper">
    <div class="wrapper__card">
      <div class="wrapper__card__header">
        <strong>Barra de filtros</strong>
      </div>
      <div class="wrapper__card__body">
        <!-- <strong>Container com as informações</strong> -->
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.wrapper {
  color: $z-black-1;
  padding: $z-s-1;
  height: calc(100vh - 48px);
  &__card {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__header {
      padding: $z-s-1;
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.10);
      border-radius: 15px;
      display: flex;
      align-items: center;
      gap: $z-s-1;
      margin-bottom: $z-s-1;
      flex-wrap: wrap;
    }
    &__body {
    //   border: 2px solid $z-black-1;
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.10);
      border-radius: 15px;
      padding: $z-s-1;
      max-height: 100%;
      flex: 1 1 100%;
      overflow-y: auto;
      @include trackScrollBar;
      display: flex;
      align-items: center;
      gap: $z-s-1;
      flex-wrap: wrap;

      &>div{
        flex: 1 1 500px;
        height: 100%;
        // border: 1px solid $z-black-1;
        border-radius: 15px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.50);
      }
    }
  }
}
</style>
